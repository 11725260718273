import { AxiosPromise } from 'axios';
import { ApiResponseEntity, UpdateStatusDTO } from '../types/common';
import { ServiceDTO, ServiceEditDTO } from '../types/serviceTypes';

import axios from '../utils/axios';

const API_PATH = '/secured/services';

export default class ServiceApi {
    static getServices(): AxiosPromise<ApiResponseEntity<ServiceDTO[]>> {
        return axios.get(API_PATH);
    }

    static getService(id: number): AxiosPromise<ApiResponseEntity<ServiceDTO>> {
        return axios.get(`${API_PATH}/${id}`);
    }

    static createService(data: ServiceEditDTO): AxiosPromise<ApiResponseEntity<number>> {
        return axios.post(`${API_PATH}`, data);
    }

    static updateService(data: ServiceEditDTO): AxiosPromise<ApiResponseEntity<ServiceDTO>> {
        return axios.put(`${API_PATH}/${data.id}`, data);
    }

    static updateServiceStatus(data: UpdateStatusDTO): AxiosPromise<ApiResponseEntity<ServiceDTO>> {
        return axios.put(`${API_PATH}/${data.id}/status`, data);
    }
}