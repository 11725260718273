import { Spin } from 'antd';

import styles from '../Design.module.css';

interface StateProps {
    loading: boolean;
    children?: React.ReactNode;
}

const Fallback = ({ loading, children }: StateProps) => {
    return (
        <Spin spinning={loading} tip="Načítám data..." size="large" className={styles.Fallback}>
            {children}
        </Spin>
    )
}

export default Fallback;