import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk';
import SecurityApi from '../api/securityApi';
import { AuthDTO, LoginParams } from '../types/securityTypes';
import { createMessage } from '../utils/common_utils';
import { createAsyncThunk } from '@reduxjs/toolkit';


let emplLogin: ReturnType<typeof createAsyncThunk<AuthDTO, LoginParams, AsyncThunkConfig>>;

export function inicializeEmplLogin() {
    emplLogin = createAsyncThunk(
        'auth/login',
        async ({ email, password }: LoginParams, {rejectWithValue}) => {
            const { data, status, message } = (await SecurityApi.login({email, password})).data;
            if (status !== 'success') {
                createMessage('warning', message);
                return rejectWithValue(message);
            }
    
            createMessage(status, 'Vítej zpátky ' + data.employee.name + ' ' + data.employee.surname + '!');
            return data;
        }
      );
}

export function getEmplLogin() {
    return emplLogin;
}