import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';


const Custom403: React.FC = () => {
    const navigation = useNavigate();

    return (
        <Result
            status="403"
            title="403"
            subTitle="Na toto nemáte dostatečné oprávnění."
            extra={<Button type="primary" onClick={() => navigation('/secured/dashboard')}>Zpět na dashboard</Button>}
        />
    );
};

export default Custom403;