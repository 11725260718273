import { AnyAction, Store, ThunkDispatch, combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, Persistor, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './authSlice';
import StoreState from '../types/securityTypes';
import { inicializeEmplLogin } from './authActions';

inicializeEmplLogin();

const persistConfig:PersistConfig<StoreState> = {
  key: 'root',
  storage
};

const rootReducer = combineReducers({
  auth: authReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store: Store<StoreState>;
let persistor: Persistor;

export function initializeStore() {
  store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  persistor = persistStore(store);
  return {store,  persistor};
}

export function getStore() {
  return store;
}

export function getState(): StoreState {
  return store.getState();
}

export function getThunkDispatch() {
  return store.dispatch as ThunkDispatch<StoreState, void, AnyAction>;
}