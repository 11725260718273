import { AxiosPromise } from 'axios';
import { ApiResponseEntity, UpdateStatusDTO } from '../types/common';
import { CustomerDTO, CustomerEditDTO } from '../types/customerTypes';

import axios from '../utils/axios';

const API_PATH = "/secured/customers";

export default class CustomerApi {
    static getCustomers(): AxiosPromise<ApiResponseEntity<CustomerDTO[]>> {
        return axios.get(API_PATH);
    }

    static createCustomer(data: CustomerEditDTO): AxiosPromise<ApiResponseEntity<number>> {
        return axios.post(API_PATH, data);
    }

    static getCustomer(id: number): AxiosPromise<ApiResponseEntity<CustomerDTO>> {
        return axios.get(API_PATH + "/" + id);
    }

    static updateCustomer(data: CustomerEditDTO): AxiosPromise<ApiResponseEntity<CustomerDTO>> {
        return axios.put(API_PATH + "/" + data.id, data);
    }

    static updateCustomerStatus(data: UpdateStatusDTO): AxiosPromise<ApiResponseEntity<CustomerDTO>> {
        return axios.put(`${API_PATH}/${data.id}/status`, data);
    }

    static verifyEmail(ide: number): AxiosPromise<ApiResponseEntity<CustomerDTO>> {
        return axios.put(`${API_PATH}/${ide}/verify-email`, {});
    }
}