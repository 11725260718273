import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';


const Custom500: React.FC = () => {
    const navigation = useNavigate();
    
    return (
        <Result
            status="500"
            title="500"
            subTitle="Shit happens sometimes."
            extra={<Button type="primary" onClick={() => navigation('/secured/dashboard')}>Zpět na dashboard</Button>}
        />
    );
};

export default Custom500;