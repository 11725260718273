import { AxiosPromise } from 'axios';
import { ApiResponseEntity } from '../types/common';
import { AuthDTO, LoginParams } from '../types/securityTypes';

import axios from '../utils/axios';

const API_PATH = '/';

export default class SecurityApi {
    static login(data: LoginParams): AxiosPromise<ApiResponseEntity<AuthDTO>> {
        return axios.post(API_PATH, data);
    }
}