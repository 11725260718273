import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { ConfigProvider, Empty } from 'antd';
import cs_CZ from 'antd/es/locale/cs_CZ';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import './index.css';

import Fallback from './components/fallback/fallback';
import { initializeStore } from './redux/store';

const { store, persistor } = initializeStore();

const app = (
    <ConfigProvider locale={cs_CZ} /*theme={{token: {colorPrimary: '#EB2F96'}}}*/ renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Žádná data k zobrazení'/>}>
        <Provider store={store}>
            <PersistGate loading={<Fallback loading />} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </ConfigProvider>
);

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element.');
const root = createRoot(rootElement);
root.render(app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
