import { AuthDTO, AuthState } from '../types/securityTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getEmplLogin } from './authActions';
import { EmployeeDTO } from '../types/employeeTypes';


const initialState: AuthState = {
    loading: false,
    isAdmin: false,
    token: undefined,
    error: undefined,
    info: undefined,
    employee: undefined,
    expirationDate: undefined
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state: AuthState) => {
      state.loading = false;
      state.isAdmin = false;
      state.info = undefined;
      state.token = undefined;
      state.error = undefined;
    },
    updateEmployee: (state: AuthState, action: PayloadAction<EmployeeDTO>) => {
      state.employee = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getEmplLogin().pending, (state: AuthState) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(getEmplLogin().fulfilled, (state: AuthState, { payload }: PayloadAction<AuthDTO>) => {
      state.loading = false;
      state.error = undefined;
      state.info = payload;
      state.isAdmin = payload.role === 'ADMIN';
      state.employee = payload.employee;
      state.employee.email = payload.email;
      state.token = payload.token;
      state.expirationDate = new Date(new Date().getTime() + 86340000);
    })
    .addCase(getEmplLogin().rejected, (state: AuthState, action) => {
      state.loading = false;
      state.error = action.payload as string || action.error.message || '';
    });
  },
});

export const { logout, updateEmployee } = authSlice.actions
export default authSlice.reducer;
