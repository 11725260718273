import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

const Custom404: React.FC = () => {
    const navigation = useNavigate();

    return (
        <Result
            status="404"
            title="404"
            subTitle="Takovou stránku tady nevedeme. Kde jste ji našli?"
            extra={<Button type="primary" onClick={() => navigation('/secured/dashboard')}>Zpět na dashboard</Button>}
        />
    );
};

export default Custom404;