import { AxiosPromise } from 'axios';
import { ApiResponseEntity, UpdateStatusDTO } from '../types/common';
import { EmployeeDTO, EmployeeEditDTO, EmployeeEditPasswordDTO, EmployeeEditRoleDTO } from '../types/employeeTypes';

import axios from '../utils/axios';

const API_PATH = '/secured/employees';

export default class EmployeeApi {
    static getEmployees(): AxiosPromise<ApiResponseEntity<EmployeeDTO[]>> {
        return axios.get(API_PATH);
    }

    static getActiveEmployees(): AxiosPromise<ApiResponseEntity<EmployeeDTO[]>> {
        return axios.get(`${API_PATH}/active`);
    }

    static getEmployee(id: number): AxiosPromise<ApiResponseEntity<EmployeeDTO>> {
        return axios.get(`${API_PATH}/${id}`);
    }

    static createEmployee(data: EmployeeEditDTO): AxiosPromise<ApiResponseEntity<number>> {
        return axios.post(`${API_PATH}`, data);
    }

    static updateEmployee(data: EmployeeEditDTO): AxiosPromise<ApiResponseEntity<EmployeeDTO>> {
        return axios.put(`${API_PATH}/${data.id}`, data);
    }

    static updateEmployeePassword(data: EmployeeEditPasswordDTO): AxiosPromise<ApiResponseEntity<void>> {
        return axios.put(`${API_PATH}/${data.id}/password`, data);
    }

    static updateEmployeeRole(data: EmployeeEditRoleDTO): AxiosPromise<ApiResponseEntity<EmployeeDTO>> {
        return axios.put(`${API_PATH}/${data.id}/role`, data);
    }

    static updateEmployeeStatus(data: UpdateStatusDTO): AxiosPromise<ApiResponseEntity<EmployeeDTO>> {
        return axios.put(`${API_PATH}/${data.id}/status`, data);
    }
}